.accordeon {
  background-color: #d6ccc2;
  display: flex;
  flex-direction: column;
  margin: 8px 0;
  border-radius: 5px;
}

.accordeon_title_container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 50px;
  cursor: pointer;
}
.svg {
  position: absolute;
  right: 0;
  margin-right: 20px;
  font-size: 2rem;
  text-align: center;
}
.accordeon_title {
  text-align: center;
  font-size: 1.2em;
  user-select: none;
  width: 80%;
}

.accordeon_wrapper {
  width: 100%;
  min-height: 25px;
  overflow: hidden;
}

.project_paragraph_title {
  font-size: 1.5em;
}

.project_paragraph {
  font-size: 1.1rem;
  line-height: 1.4;
  font-family: Montserrat;
  margin: 16px 0;
}

@media (max-width: 640px) {
  .accordeon_title_container {
    min-height: 80px;
  }
  .accordeon_title {
    text-wrap: pretty;
    text-align: center;
  }
}
@media (max-width: 360px) {
  .accordeon_title_container {
    min-height: 120px;
  }
}
