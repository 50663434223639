:root {
  --margin: 24px 0;
}
.profile_container {
  background-color: #f1f1f1;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.profile_item {
  background-color: var(--tertiary-color);
  height: 100%;
  min-height: 300px;
  justify-items: center;
  align-items: center;
  display: flex;
  justify-content: space-around;
}

.profile_paragraph_container {
  min-width: 300px;
  max-width: 50%;
  margin: var(--margin);
  text-align: justify;
}
.profile_paragraph {
  font-size: 1.1rem;
  min-height: 100px;
  line-height: 1.4;
  font-family: Montserrat;
  margin: 16px 0;
}
.profile_picture_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: var(--margin);
  justify-content: space-around;
}
.profile_picture {
  min-height: 200px;
  min-width: 200px;
  height: 300px;
  width: 300px;
  object-fit: fill;
}
.media_container {
  display: flex;
  justify-content: space-around;
}

.profile_description {
  grid-column: 1/2;
  background-color: #224a54;
  height: 100%;
  width: 100%;
  color: #f0f8ff;
}
@media (max-width: 800px) {
  .profile_item {
    flex-direction: column;
    width: 100%;
  }
  .media_container {
    margin: 32px 0 0 0;
  }
  .profile_paragraph_container {
    max-width: 100%;
    padding: 0 16px;
  }
}

@media (max-width: 425px) {
  .profile_item {
    flex-direction: column;
    width: 100%;
  }

  .profile_paragraph_container {
    min-width: unset;
    max-width: unset;
    padding: 0 8px;
  }

  .profile_paragraph {
    width: 100%;
    padding: 0 8px;
    text-align: left;
  }
  .profile_picture {
    height: 250px;
    width: 250px;
  }
}
