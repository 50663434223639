.link {
  color: #2d2d2d;
}
.link:visited {
  color: #2d2d2d;
}
.icon {
  width: 50px;
  height: 50px;
  opacity: 0.9;
}
.icon:hover {
  color: #ffffff;
  opacity: 1;
  -webkit-filter: drop-shadow(0px 0px 15px #222);
  filter: drop-shadow(0px 0px 15px #222);
  transition: color 0.3s ease-in-out;
}
.icon:active {
  -webkit-filter: drop-shadow(
    rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset
  );
  filter: drop-shadow(
    rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset
  );
} 
