.main {
  padding-top: 120px;
  background-color: #f5f8fb;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 1024px) {
  .main {
    padding-top: 80px;
  }
}
@media (max-width: 600px) {
  .main {
    padding-top: 40px;
  }
}
@media (max-width: 425px) {
  .main {
    padding-top: 20px;
  }
}
