* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --primary-color: #d5bdaf;
  --secondary-color: #e3d5ca;
  --tertiary-color: #f5ebe0;
  --quaternary-color: #d6ccc2;
  --quinary-color: #edede9;
  --primary-text-color: #313131;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

/*
font-family: 'Montserrat', sans-serif;
font-family: 'Ubuntu', sans-serif;*/
