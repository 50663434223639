.block_container {
  margin: 100px 48px;
  max-width: 1440px;
  background-color: rgba(0, 0, 0, 0);
  min-height: 688px;
  color: var(--primary-text-color);
  display: grid;
  grid-template-rows: 140px repeat(2, 1fr);
  grid-template-columns: repeat(3, 1fr);
  animation: block-load 1200ms ease-in;
  position: relative;
}

.block_title_container {
  z-index: 10;

  user-select: none;
  grid-row: 1;
  grid-column: 1/4;
  position: relative;
}
.block_title {
  font-size: 160px;
  font-weight: 800;
  height: 180px;
  margin-left: 30px;
}

.block_article {
  background-color: var(--secondary-color);
  grid-row: 2/4;
  grid-column: 1/4;
  padding: 40px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

@keyframes block-load {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 850px) {
  .block_title {
    font-size: 140px;
  }
  .block_container {
    margin: 100px 16px;
  }
}

@media (max-width: 768px) {
  .block_title {
    font-size: 120px;
  }
}

@media (max-width: 600px) {
  .block_title {
    font-size: 88px;
    margin-top: 56px;
    margin-left: 15px;
  }
  .block_article {
    padding: 24px;
  }
}

@media (max-width: 425px) {
  .block_title {
    font-size: 82px;
    margin-left: 0px;
    margin-top: 56px;
  }
  .block_article {
    padding: 40px 16px;
  }
  .block_article {
    padding: 12px;
  }
}
@media (max-width: 385px) {
  .block_title {
    font-size: 78px;
    margin-left: 0px;
    margin-top: 62px;
  }
}
@media (max-width: 370px) {
  .block_title {
    font-size: 66px;
    margin-left: 0px;
    margin-top: 76px;
  }
}
