.navbar {
  background-color: var(--primary-color);
  color: var(--primary-text-color);
  font-family: "Ubuntu", sans-serif;
  position: absolute;
  top: 0;
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: navbar-load 800ms ease-in;
}

.list {
  display: flex;
  list-style: none;
  justify-content: space-around;
  width: 100%;
  align-items: center;
}

.list li {
  font-size: 30px;
  cursor: pointer;
  user-select: none;
  position: relative;
  z-index: 1;
  animation: fade-in 1200ms ease-in;
}

a {
  position: relative;
  color: #000;
  text-decoration: none;
}

.list li:hover {
  color: #000;
}

.list li::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.list li:hover::before {
  transform: scaleX(1);
}

.overlay {
  color: var(--primary-text-color);
  position: relative;
  text-decoration: none;
  width: 100%;
  height: 100%;
  font-family: Montserrat;
  font-size: 1.3rem;
}

.overlay::before {
  background: #b8a1e1;
  content: "";
  inset: 0;
  position: absolute;
  opacity: 0;
  transform: translate(2px, 2px);
  transition: transform 0.4s ease-in-out;
  transition-delay: 0.1s;
  z-index: -1;
}

.overlay:hover::before,
.overlay:focus::before {
  opacity: 0.8;
  transform: translate(14px, -8px);
}

.logo {
  width: 140px;
  cursor: pointer;
  user-select: none;
  position: relative;
  z-index: 1;
}

.logo h2:first-child {
  text-align: left;
}

.logo h2:last-child {
  text-align: right;
}

.logo h2 span {
  position: relative;
}

.logo h2 span::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 0;
  transition: transform 400ms ease-in-out;
  transform: scaleY(0);
  transform-origin: bottom;
}

.logo:hover h2 span::before,
.logo:focus h2 span::before {
  background-color: #b8a1e1;
  height: 100%;
  transform: scaleY(1);
  z-index: -1;
}
/*use :has(img   svg)  for logo?*/
@keyframes navbar-load {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes sidebar-slideIn {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes sidebar-slideOut {
  0% {
    opacity: 0;
    transform: translateX(0);
  }
  100% {
    opacity: 1;
    transform: translateX(-100%);
  }
}
.sidebar {
  display: none;
}

@media screen and (max-width: 768px) {
  .navbar {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-items: center;
  }
  .sidebar {
    display: block;
    position: absolute;
    height: 100vh;
    width: 70%;
    background-color: #d5bdaf;
    z-index: 9999;
    margin-top: 120px;
    animation: sidebar-slideIn 300ms ease-in-out;
    transition: all 0.3s linear;
    opacity: 0.9;
  }
  .sidebar_list {
    padding: 24px 0 24px 0;
    display: flex;
    flex-direction: column;
    height: 50%;
    justify-content: space-around;
    align-items: center;
  }
  .sidebar_list > li {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    list-style: none;
    color: var(--primary-text-color);
    font-size: 1.5em;
    cursor: pointer;
    margin: 10px 0 10px 0;
  }

  .overlay {
    color: var(--primary-text-color);
    position: relative;
    text-decoration: none;
    width: 80%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.6rem;
  }

  .overlay::before {
    background: #b8a1e1;
    content: "";
    inset: 0;
    position: absolute;
    opacity: 0;
    transform: translate(2px, 2px);
    transition: transform 0.4s ease-in-out;
    transition-delay: 0.1s;
    z-index: -1;
  }

  .overlay:hover::before,
  .overlay:focus::before {
    opacity: 0.8;
    transform: translate(14px, -8px);
  }

  .list > *:not(.logo) {
    display: none;
    grid-column: 2/3;
  }

  .Hamburger {
    grid-column: 1/2;
    user-select: none;
  }

  .Hamburger > div {
    transition: all 0.3s linear;
    height: 5px;
    width: 30px;
    margin: 5px 0;
    transform-origin: 1px;
    background-color: var(--primary-text-color);
  }
}
